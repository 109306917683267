import { NextPage } from 'next';
import React from 'react';
import styled from 'styled-components';
import { Hero } from '@hotelplan/components.common.hero';
import {
  PageLanguage,
  PageType,
  RequestContextInput,
  StaticPageStatus,
} from '@hotelplan/graphql.types';
import { apolloUnitRef } from '@hotelplan/libs.apollo-next-api';
import { OBJECT_ID_PROP } from '@hotelplan/libs.context.object-id';
import { i18nUnitRef } from '@hotelplan/libs.i18n-api';
import { objectIdUnit } from '@hotelplan/libs.object-id-api';
import { createOpenGraphProp } from '@hotelplan/libs.open-graph';
import {
  apolloReqCtxUnitRef,
  reqCtxUnitRef,
} from '@hotelplan/libs.req-ctx-api';
import { routeConfigUnitRef } from '@hotelplan/libs.route-config-api';
import { ServerSideUnit } from '@hotelplan/libs.ssp-units';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import { DynamicComponents } from 'components/domain/dynamic-components';
import StyledTextBlock from 'components/domain/StyledTextBlock';
import { serverCorrectUrlUnit } from 'config/all';
import { createPageServerFn } from 'config/pageUnitsConfig';
import {
  GetStaticPageContentDocument,
  GetStaticPageContentQuery,
  GetStaticPageContentQueryVariables,
  useGetStaticPageContentQuery,
} from 'graphql/static/GetStaticPageContent.generated';
import {
  GetStaticPageMetaDataDocument,
  GetStaticPageMetaDataQuery,
  GetStaticPageMetaDataQueryVariables,
} from 'graphql/static/GetStaticPageMetaData.generated';
import {
  GetStaticPageUrlDocument,
  GetStaticPageUrlQuery,
  GetStaticPageUrlQueryVariables,
} from 'graphql/static/GetStaticPageURL.generated';

const InformationWrapper = styled(StyledTextBlock)(
  sx2CssThemeFn({
    py: [5, '75px'],
    px: [4, 0],
  })
);

const StaticDynamicComponents = styled(DynamicComponents)(
  sx2CssThemeFn({
    '.media-gallery': {
      mb: [5, '75px'],
      '.slider-control-centerleft': {
        left: '25px!important',
      },
      '.slider-control-centerright': {
        right: '25px!important',
      },
    },
    pb: [null, '75px'],
  })
);

const StaticPage: NextPage<{ url: string }> = ({ url }) => {
  const { data } = useGetStaticPageContentQuery({
    variables: {
      url,
    },
  });

  const {
    hero,
    title = '',
    text = '',
    textBgColor,
    textFgColor,
    textBgImage,
    removeHeaderFooter,
  } = data?.static || {};

  if (removeHeaderFooter) {
    return <DynamicComponents placeholder={<></>} />;
  }

  return (
    <>
      {hero && <Hero image={hero} />}
      <Container>
        {title || text ? (
          <InformationWrapper
            title={title}
            text={text}
            fontColor={textFgColor}
            backgroundColor={textBgColor}
            backgroundImage={textBgImage}
          />
        ) : null}
      </Container>
      <StaticDynamicComponents />
    </>
  );
};

const staticObjectIdUnit = ServerSideUnit.depsOf([routeConfigUnitRef])(
  async (
    ctx,
    { pathname }
  ): Promise<Awaited<ReturnType<typeof objectIdUnit.value>>> => {
    const idContext = {
      id: pathname,
      objectId: pathname,
      objectType: '',
    };

    return {
      ...idContext,
      props: {
        [OBJECT_ID_PROP]: idContext,
      },
    };
  }
).withRef(objectIdUnit.ref);

const staticCorrectLanguageUnit = ServerSideUnit.depsOf([
  i18nUnitRef,
  apolloUnitRef,
  routeConfigUnitRef,
  reqCtxUnitRef,
])(async function correctLocale(
  ctx,
  i18nPayload,
  { apollo },
  { pathname: url },
  reqCtxPayload
) {
  const variables: GetStaticPageContentQueryVariables = {
    url,
    context: {
      ...(reqCtxPayload.context as RequestContextInput),
      language: PageLanguage.Unknown,
    },
  };

  const { data } = await apollo.query<
    GetStaticPageContentQuery,
    GetStaticPageContentQueryVariables
  >({
    query: GetStaticPageContentDocument,
    variables,
  });

  const { status, lang, removeHeaderFooter } = data.static;

  if (status === StaticPageStatus.PageDoesNotExist) {
    return {
      notFound: true,
    };
  }

  if (status !== StaticPageStatus.Success) {
    throw new Error(
      `Failed to load static page data for ${url}, status is ${status}`
    );
  }

  const realLocale = lang.toLowerCase();

  await i18nPayload.changeLanguage(realLocale);

  apollo.writeQuery({
    query: GetStaticPageContentDocument,
    variables: {
      ...variables,
      context: {
        ...variables.context,
        language: lang,
      },
    },
    data,
  });

  const heroImageUrl = data.static.hero.resized[0].url;

  return {
    props: {
      url,
      withoutLayout: removeHeaderFooter,
      ...createOpenGraphProp({ image: heroImageUrl }),
    },
  };
});

const staticCorrectUrl = serverCorrectUrlUnit<
  GetStaticPageUrlQuery,
  GetStaticPageUrlQueryVariables
>({
  query: GetStaticPageUrlDocument,
  getVariables: (
    // keep
    ctx,
    o,
    pageType,
    { pathname: url }
  ) => ({
    url,
  }),
  getUrl: data => ({
    targetPageType:
      data.static.status === StaticPageStatus.Success
        ? PageType.Static
        : PageType.Page_404,
    uri: data.static.slug || '/',
  }),
});

const staticPagePreload = ServerSideUnit.depsOf([
  apolloReqCtxUnitRef,
  routeConfigUnitRef,
])(async (ctx, { queryCtx }, { pathname: url }) => {
  const { data: dataMeta = null } = await queryCtx<
    GetStaticPageMetaDataQuery,
    GetStaticPageMetaDataQueryVariables
  >({
    query: GetStaticPageMetaDataDocument,
    variables: { url },
  });

  return {
    props: {
      meta: dataMeta.static.meta,
    },
  };
});

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.Static,
    pageEventType: `static`,
    namespaceRequired: [`common`, `forms`, `newsletter`],
    localeDetectionUnitRef: staticCorrectLanguageUnit.ref,
    objectIdUnitReplacement: staticObjectIdUnit,
  },
  [
    staticCorrectLanguageUnit,
    staticCorrectUrl.withDep(staticCorrectLanguageUnit),
    staticPagePreload.withDep(staticCorrectLanguageUnit),
  ] as any
);

export default StaticPage;
